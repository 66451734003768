import React from 'react';
import './NuxtCard.css'; // Assuming styles are moved to separate CSS file

// https://github.com/lepikhinb/example-nuxt-studio-hover/blob/master/src/components/BaseCard.vue

export function NuxtCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="border-gradient group relative rounded-xl border border-[#272725] transition before:absolute before:-inset-px before:h-[calc(100%+2px)] before:w-[calc(100%+2px)] before:rounded-xl">
            <div className="absolute inset-0 rounded-[11px] bg-zinc-900 transition-colors duration-300 group-hover:bg-[#272725]"></div>
            <div className="relative p-6">{children}</div>
        </div>
    );
}
